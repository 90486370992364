import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Content, { HTMLContent } from '../components/Content';
import Tags from '../components/Tags';
import Header from '../components/Header';
import Author from '../components/Author';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import RouterTracker from '../components/RouterTracker';

import ThemeContext from '../context/ThemeContext';

const BlogPostTemplateStyles = styled.article(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  .header-image {
    
  }
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
    &.header-image {
      padding-top: 40vh;
    }
  }
  .date {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    margin-bottom:  ${props.theme.size.base * 1.5}px;
    color: ${props.theme.color.foreground[50]};
  }
  .tags {
    margin-bottom:  ${props.theme.size.base}px;
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: ${props.theme.size.fontSize[8]};
    line-height: ${props.theme.size.lineHeight[8]};
    text-decoration: none;
    color: ${props.theme.color.foreground[100]};
    transition: color 450ms ease;
    margin: 0;
    ${
      (props.breakpoints.lg || props.breakpoints.xl) &&
      `
      font-size: ${props.theme.size.fontSize[12]};
      line-height: ${props.theme.size.lineHeight[12]};
    `
    }
  }
  .author {
    margin-top: ${props.theme.size.base * 8}px;
    padding-top: ${props.theme.size.base * 8}px;
    border-top: 2px solid ${props.theme.color.secondary[50]};
  }
`
);

export const BlogPostTemplate = ({
  content,
  contentComponent,
  date,
  description,
  headerimage,
  tags,
  title,
  helmet,
  author,
}) => {
  const breakpoints = useBreakpoint();
  const PostContent = contentComponent || Content;
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <BlogPostTemplateStyles breakpoints={breakpoints} theme={theme}>
          <RouterTracker />
          {helmet || ''}
          {headerimage ? (
            <Header
              theme={theme}
              image={headerimage}
              alt={`header image for post ${title}`}
            />
          ) : null}
          <div className={`body ${headerimage ? 'header-image' : ''}`}>
            {date && <div className='date'>{date}</div>}
            {tags && tags.length ? (
              <div className='tags'>
                <Tags tags={tags}></Tags>
              </div>
            ) : null}
            <h1 className='title'>{title}</h1>
            <div className='typesetter'>
              <p className='lead'>{description}</p>
              <PostContent content={content} />

              {author && (
                <div className='author'>
                  <Author theme={theme} author={author.frontmatter} />
                </div>
              )}
            </div>
          </div>
        </BlogPostTemplateStyles>
      )}
    </ThemeContext.Consumer>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  date: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  author: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <BlogPostTemplate
      content={post.html}
      contentComponent={HTMLContent}
      date={post.frontmatter.date}
      description={post.frontmatter.description}
      headerimage={post.frontmatter.headerimage}
      helmet={
        <Helmet titleTemplate='%s | Blog'>
          <title>{`${post.frontmatter.title}`}</title>
          <meta
            name='description'
            content={`${post.frontmatter.description}`}
          />
        </Helmet>
      }
      tags={post.frontmatter.tags}
      title={post.frontmatter.title}
      author={data.authors.nodes.find(
        (author) => author.frontmatter.name === post.frontmatter.author
      )}
    />
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        headerimage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        title
        description
        tags
        author
      }
    }
    authors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "author" } } }
    ) {
      nodes {
        frontmatter {
          name
          job_title
          bio
          social {
            name
            url
          }
          profile_picture {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 60, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
