import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import PreviewCompatibleImage from './PreviewCompatibleImage';

var tinycolor = require('tinycolor2');

const HeaderStyles = styled.div(
  (props) => `
  z-index: 100;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  opacity: ${props.theme.dark ? props.opacity.dark : props.opacity.light};
  img {
    object-fit: cover;
    width: 105%;
    height: 105%;
    margin-top: -2.5%;
    margin-left: -2.5%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      0,
      ${tinycolor(props.theme.color.background[30]).setAlpha(1).toString()},
      ${tinycolor(props.theme.color.tertiary[50]).setAlpha(0).toString()}
      );
      border: 0;
    }
  }
  ${
    props.breakpoints.lg &&
    `
      border-radius:  ${props.theme.size.base * 4}px 0 0 0;
  `
  };
  ${
    props.breakpoints.xl &&
    `
      border-radius:  ${props.theme.size.base * 3}px 0 0 0;
  `
  };
`
);

const Header = (props) => {
  const breakpoints = useBreakpoint();
  const opacity = props.opacity ? props.opacity : { dark: 0.5, light: 0.7 };
  useEffect(() => {});

  return (
    <HeaderStyles
      theme={props.theme}
      breakpoints={breakpoints}
      opacity={opacity}
    >
      <PreviewCompatibleImage
        imageInfo={{
          image: props.image,
          alt: `header image for post ${props.alt}`,
        }}
      />
    </HeaderStyles>
  );
};

Header.propTypes = {
  theme: PropTypes.object,
  image: PropTypes.any,
  alt: PropTypes.string,
  opacity: PropTypes.object,
};

export default Header;
