import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const _ = require('lodash');

const AuthorStyles = styled.div(
  (props) => `
  display: flex;
  .author-image {
    background: ${props.theme.color.background[80]};
    width: ${props.theme.size.base * 12}px;
    height: ${props.theme.size.base * 12}px;
    flex-shrink: 0;
    border-radius: 999px;
    overflow: hidden;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  .author-info {
    padding-left: ${props.theme.size.base * 2}px;
    max-width: ${props.theme.size.base * 72}px;
  }
  .author-social {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    margin-bottom: ${props.theme.size.base * 0.5}px;
    a {
      color: ${
        props.theme.dark
          ? props.theme.color.accent[60]
          : props.theme.color.accent[40]
      };
      text-decoration: none;
      transition: color 450ms ease;
      &:hover {
        color: ${
          props.theme.dark
            ? props.theme.color.primary[60]
            : props.theme.color.primary[40]
        };
      }
    }
  }
  .author-name {
    font-size: ${props.theme.size.fontSize[4]};
    line-height: ${props.theme.size.lineHeight[4]};
    color: ${props.theme.color.foreground[100]};
    transition: color 1200ms ease;
    margin-bottom: ${props.theme.size.base * 0.5}px;
    a {
      color: ${props.theme.color.foreground[100]};
      text-decoration: none;
      transition: color 450ms ease;
      &:hover {
        color: ${props.theme.color.tertiary[60]};
      }
    }
  }
  .author-title {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    text-decoration: none;
    color: ${
      props.theme.dark
        ? props.theme.color.secondary[60]
        : props.theme.color.secondary[40]
    };
    transition: color 1200ms ease;
  }
  .author-bio {
    font-size: ${props.theme.size.fontSize[4]};
    line-height: ${props.theme.size.lineHeight[4]};
    color: ${props.theme.color.foreground[40]};
    transition: color 1200ms ease;
    font-weight: 200;
  }
`
);

const Author = (props) => {
  const breakpoints = useBreakpoint();

  return (
    <AuthorStyles theme={props.theme} breakpoints={breakpoints}>
      <Link to={`/authors/${_.kebabCase(props.author.name)}`}>
        <div className='author-image'>
          <PreviewCompatibleImage
            imageInfo={{
              image: props.author.profile_picture,
              alt: `Avatar image for ${props.author.name}`,
            }}
          />
        </div>
      </Link>
      <div className='author-info'>
        <div className='author-name'>
          <Link to={`/authors/${_.kebabCase(props.author.name)}`}>
            {props.author.name}
          </Link>
        </div>
        {props.author.job_title && (
          <div className='author-title'>{props.author.job_title}</div>
        )}
        {props.author.social && (
          <div className='author-social'>
            {props.author.social.map((link, index) => (
              <a
                key={`author-social-${index}`}
                href={link.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {link.name}
              </a>
            ))}
          </div>
        )}
        {props.author.bio && (
          <div className='author-bio'>
            <HTMLContent content={props.author.bio} />
          </div>
        )}
      </div>
    </AuthorStyles>
  );
};

Author.propTypes = {
  theme: PropTypes.object,
  author: PropTypes.object,
};

export default Author;
